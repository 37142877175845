import Amplify, { Auth } from 'aws-amplify'
import { GraphQlStages } from '../common/graphql'

const intCognitoPool = {
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_KPeNhiSiJ',
  userPoolWebClientId: '3t24qigm3mr6n12lh0qhd70go1',
}

const prdCognitoPool = {
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_iCTRSlSvV',
  userPoolWebClientId: '5p2ukqmqvbn8p3g4cnc6kfa2au',
}

export const isProd = () => {
  return window.location.hostname.includes('operationsapp.moia.io')
}

const getPool = () => {
  return isProd() ? prdCognitoPool : intCognitoPool
}

export const getApiUrl = () => {
  return isProd() ? GraphQlStages.prod : GraphQlStages.int
}

const getSubDomain = () => {
  return isProd() ? `moia-ops` : `moiahelp1595869492`
}

export const getAgentUrl = () => {
  const subdomain = getSubDomain()

  return `https://${subdomain}.zendesk.com/agent`
}

export const getClassicUrl = () => {
  const subdomain = getSubDomain()

  return `https://${subdomain}.zendesk.com/access/normal`
}

Amplify.configure(getPool())
export const getCognitoToken = async ({ username, password }: any) => {
  const user = await Auth.signIn(username, password)
  const token = user.signInUserSession.idToken.jwtToken
  return token
}
