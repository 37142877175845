import { languages } from './languages'

export const messages = {
  [languages.deDE]: {
    [languages.deDE]: 'DE',
    [languages.enGB]: 'EN',
    heroCTAContainer: 'Operations App \njetzt im Appstore',
    heroDescription: 'Hilfe zur Operations App bekommst du bei deinem Driver Manager oder direkt im Help Center.',
    heroDesktopPrompt:
      'Die Installation ist nur in Safari möglich. Öffne diese Seite in Safari (nicht im Inkognito Modus) auf deinem iOS Gerät, um die App zu installieren.',
    heroCta: 'Zum Help Center',
    heroHelpLink: 'Hilfe bei Erstinstallation',
    releaseNotesHeadline: 'Was ist neu?',
    descriptionHeadline: 'Beschreibung',
    descriptionText: `MOIA Operations - Die App für MOIA Fahrer und Servicemitarbeiter
Die MOIA OPS App unterstützt dich bei deiner täglichen Arbeit als Fahrer und Servicemitarbeiter bei MOIA. Sie erleichtert sämtliche Arbeitsabläufe mit intuitiven Tools. Ein paar Highlights:

* Dein persönliches Dashboard – immer über alles informiert

* Deine Schichtübersicht – vorausschauend planen und organisieren

* Die MOIA Fahrzeugsuche per App – schnell dein MOIA finden, einsteigen und Arbeitstag starten
und mit vielen weiteren Workflow-Tools.

Mach deine Stadt zu einem lebenswerteren Ort. Indem du Menschen mit einem völlig neuen Mobilitätskonzept von A nach B durch die Stadt bringst:


Als Fahrer bei MOIA, dem innovativen Ridesharing-Service. Mit eigens entwickelten MOIA Fahrzeugen, die vollelektrisch und leise durch die Stadt gleiten und keine lokalen Emissionen verursachen.`,
    descriptionToggleButton: 'Mehr anzeigen',
    troubleShootingHeadline: 'Probleme bei der Installation?',
    troubleShootingDescription: `Solltest du Probleme bei der Installation haben, kannst du dir die Installationsanleitung im Helpcenter noch einmal anschauen.`,
    troubleShootingLink: 'Zum Helpcenter',
    footerPolicy: 'Datenschutzerklärung',
    footerLegal: 'Rechtliches',
    footerConduct: 'Code of Conduct',
    footerImprint: 'Impressum',
    footerCopyright: '© {year} - MOIA | All rights reserved',
    loginFormHeader: 'Single Sign-on für MOIA Operations',
    loginFormContent: 'Verwende hier dieselben Login-Daten wie in der Operations App!',
    loginFormForgotPasswordDescription: 'Falls du dein Passwort nicht mehr kennst:',
    loginFormForgotPasswordStep1: 'logge dich aus der Driver app aus.',
    loginFormForgotPasswordStep2: 'gib deine E-mail addresse ein.',
    loginFormForgotPasswordStep3: 'sowie ein falsches Passwort.',
    loginFormForgotPasswordStep4: 'Wähle "Passwort vergessen" und erhalte einen Code per SMS',
    loginFormForgotPasswordStep5: 'Gebe diesen Code ein und vergib ein neues Passwort :)',
    loginEmailLabel: 'Email Adresse',
    loginPasswordLabel: 'Passwort',
    loginSubmit: 'Anmelden',
    loginError: 'Das hat leider nicht geklappt',
    loginCredentialsError: `Das Passwort oder die Email Adresse ist falsch`,
    loginMailError: `Für diese Email Adresse ist kein Nutzer angelegt`,
    loginAgent: `Login mit Microsoft`,
    loginClassic: `Klassischer Login`
  },
  [languages.enGB]: {
    [languages.deDE]: 'DE',
    [languages.enGB]: 'EN',
    heroCTAContainer: 'Operations app \nnow in the Appstore ',
    heroDescription: `For help with the Operations App, contact your Driver Manager or go directly to the Help Center.`,
    heroDesktopPrompt:
      'Install is only available in Safari. Open this page in Safari (in non-incognito mode) on your iOS device to install the app.',
    heroCta: 'To Help Center',
    heroHelpLink: 'Help for first time installation',
    releaseNotesHeadline: 'What’s new?',
    descriptionHeadline: 'Discription',
    descriptionText: `MOIA Operations - The MOIA employee app for drivers and service staff

The MOIA OPS app helps you get the most out of your daily work as a MOIA driver and service staff. It simplifies all your workflows with intuitive tools. A few highlights:

* Your personal dashboard - always informed about everything
* Your shift overview - planning and organizing with foresight
* The MOIA vehicle search via app - quickly find your MOIA, get in and start your working day

and with many other workflow tools.

Make your city a better place to live. By taking people from A to B through the city with a completely new mobility concept:

As a driver with MOIA, the innovative ridesharing service. With specially developed MOIA vehicles that glide through the city all-electrically and quietly, with no local emissions.`,
    descriptionToggleButton: 'Show more',
    troubleShootingHeadline: 'Issues with the installation?',
    troubleShootingDescription: `If you should have issues with the installation, you can check the installation instructions at the helpcenter.`,
    troubleShootingLink: 'Go to Helpcenter',
    footerPolicy: 'Privacy Policy',
    footerLegal: 'Legal',
    footerConduct: 'Code of Conduct',
    footerImprint: 'Imprint',
    footerCopyright: '© {year} - MOIA | All rights reserved',
    loginFormHeader: 'Single Sign-on for MOIA Operations',
    loginFormContent: 'Use the same credentials as Operations App!',
    loginFormForgotPasswordDescription: 'In case you don\'t remember your password:',
    loginFormForgotPasswordStep1: 'Logout from Driver app.',
    loginFormForgotPasswordStep2: 'Enter your E-mail address.',
    loginFormForgotPasswordStep3: 'Fill in a wrong password.',
    loginFormForgotPasswordStep4: 'Select "Forgot password" and you will receive code via SMS',
    loginFormForgotPasswordStep5: 'Enter this code and choose a new password :)',
    loginEmailLabel: 'Email Address',
    loginPasswordLabel: 'Password',
    loginSubmit: 'Sign In',
    loginError: `Incorrect username or password`,
    loginCredentialsError: `Incorrect password`,
    loginMailError: `User does not exist`,
    loginAgent: `Login with Microsoft`,
    loginClassic: `Classic Login`
  },
}
