import React from 'react'
import { useIntl } from 'react-intl'
import { Helmet } from 'react-helmet'

export const Header = () => {
  const intl = useIntl()
  return (
    <Helmet>
      <title>{intl.formatMessage({ id: 'heroCTAContainer' })}</title>
      <meta
        name="description"
        content={intl.formatMessage({ id: 'heroDescription' })}
      />
    </Helmet>
  )
}
