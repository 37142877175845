import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  cta: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    width: '175px',
    height: '46px',
    borderRadius: '10px',
    fontWeight: 'bold',
    fontSize: '18px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
  },
}))

export const CtaLink: React.FC<{ href: string; className?: string }> = ({
  children,
  className = '',
  href,
}) => {
  const styles = useStyles()
  return (
    <a className={`${styles.cta} ${className}`} href={href}>
      {children}
    </a>
  )
}
