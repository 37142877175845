import React from 'react'
import { Button, Menu, MenuItem, makeStyles } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

import { Angle } from '../icons/angle'
import { languages } from './languages'

const LANGUAGE_STORAGE_KEY = 'SELECTED_DEFAULT_LANGUAGE'

export const getPersistedSelectedLanguage = () => {
  return localStorage.getItem(LANGUAGE_STORAGE_KEY) || languages.deDE
}

export const useLanguages = () => {
  const selectableLanguages = Object.values(languages)
  const [selectedLanguage, setSelectedLanguage] = React.useState(
    getPersistedSelectedLanguage(),
  )
  const handleSelectedLanguageChanged = (language: string) => {
    setSelectedLanguage(language)
    localStorage.setItem(LANGUAGE_STORAGE_KEY, language)
  }

  return {
    selectableLanguages,
    selectedLanguage,
    handleSelectedLanguageChanged,
  }
}

export type LanguageProps = ReturnType<typeof useLanguages>
type LanguageSwitcherProps = ReturnType<typeof useLanguages>

const useStyles = makeStyles(theme => ({
  button: { color: theme.palette.common.white },
}))

export const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
  selectableLanguages,
  selectedLanguage,
  handleSelectedLanguageChanged,
}) => {
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null)
  const styles = useStyles()

  return (
    <>
      <Button
        startIcon={<Angle />}
        className={styles.button}
        onClick={e => setAnchor(e.currentTarget)}
      >
        <FormattedMessage id={selectedLanguage} />
      </Button>
      <Menu
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={() => setAnchor(null)}
      >
        {selectableLanguages.map(language => (
          <MenuItem
            key={language}
            onClick={() => {
              handleSelectedLanguageChanged(language)
              setAnchor(null)
            }}
          >
            <FormattedMessage id={language} />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
