import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Container } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme: any) => ({
  footer: {
    background: theme.palette.warning.superlight,
    paddingBottom: '30px',
    paddingTop: '15px',
    fontSize: '16px',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
    padding: '15px 30px 15px 0',
    [theme.breakpoints.down('xs')]: {
      minWidth: '180px',
    },
  },
  copyright: {
    marginTop: '50px',
  },
  nav: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
    },
  },
}))

const footerLinks: any = {
  'en-GB': {
    policy: 'https://www.moia.io/en/privacy-policy',
    legal: 'https://www.moia.io/en/legal',
    conduct: 'https://www.moia.io/code-of-conduct/200630_CoC_MOIA_VW_ENG.pdf',
    imprint: 'https://www.moia.io/en/imprint',
  },
  'de-DE': {
    policy: 'https://www.moia.io/de-DE/datenschutz',
    legal: 'https://www.moia.io/de-DE/rechtliches',
    conduct: 'https://www.moia.io/code-of-conduct/200630_CoC_MOIA_VW_DEU.pdf',
    imprint: 'https://www.moia.io/de-DE/impressum',
  },
}

const year = new Date().getFullYear()

export const Footer: React.FC<{ selectedLanguage: string }> = ({
  selectedLanguage,
}) => {
  const styles = useStyles()
  return (
    <footer className={styles.footer}>
      <Container>
        <Box className={styles.nav} component={'nav'}>
          <a
            className={styles.link}
            href={footerLinks[selectedLanguage].policy}
          >
            <FormattedMessage id={'footerPolicy'} />
          </a>

          <a className={styles.link} href={footerLinks[selectedLanguage].legal}>
            <FormattedMessage id={'footerLegal'} />
          </a>

          <a
            className={styles.link}
            href={footerLinks[selectedLanguage].conduct}
          >
            <FormattedMessage id={'footerConduct'} />
          </a>

          <a
            className={styles.link}
            href={footerLinks[selectedLanguage].imprint}
          >
            <FormattedMessage id={'footerImprint'} />
          </a>
        </Box>
        <Box className={styles.copyright}>
          <FormattedMessage values={{ year }} id={'footerCopyright'} />
        </Box>
      </Container>
    </footer>
  )
}
