import React from 'react'
import {
  TextField,
  Button,
  CardContent,
  Card,
  Container,
  CardHeader,
  CircularProgress,
  Box,
  Link,
} from '@material-ui/core'
import queryString from 'query-string'
import { Hero } from '../common/hero'
import { useIntl } from 'react-intl'
import {
  getCognitoToken,
  getApiUrl,
  getAgentUrl,
  getClassicUrl,
} from './cognito'
import { LanguageProps } from '../locales/language-switcher'

const querySSO = ({ token, returnTo }: any) => {
  return fetch(getApiUrl(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: token,
      mode: 'cors',
    },
    body: JSON.stringify({
      query: `query EmployeeZendeskSSO($returnTo: String!) {
        employeeZendeskSSO(returnTo: $returnTo ) {
          returnTo
        }
      }
      `,
      variables: { returnTo },
    }),
  })
}
const useLogin = () => {
  const query = queryString.parse(window.location.search)
  const returnTo = query.return_to ?? ''
  const [isLoading, setIsLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [url, setUrl] = React.useState('')

  const handleLogin = async (params: LoginSubmitArgs) => {
    setIsLoading(true)
    setErrorMessage('')
    try {
      const token = await getCognitoToken(params)
      const response = await querySSO({ token, returnTo })
      const result = await response.json()
      setUrl(result?.data?.employeeZendeskSSO?.returnTo)
    } catch (e) {
      console.error(e)
      switch (e.code) {
        case 'NotAuthorizedException':
          setErrorMessage('loginCredentialsError')
          break
        case 'UserNotFoundException':
          setErrorMessage('loginMailError')
          break
        default:
          setErrorMessage('loginError')
          break
      }
    }
    setIsLoading(false)
  }

  React.useEffect(() => {
    if (url) {
      window.location.href = url
    }
  }, [url])

  return { handleLogin, isLoading, errorMessage }
}

export const SingleSignOnPage = ({
  languageProps,
}: {
  languageProps: LanguageProps
}) => {
  const intl = useIntl()
  const { handleLogin, isLoading, errorMessage } = useLogin()
  return (
    <Hero languageProps={languageProps}>
      <Container>
        <Card style={{ opacity: '0.9', margin: '100px 0 0 0' }}>
          <CardHeader
            title={intl.formatMessage({ id: 'loginFormHeader' })}
            style={{ margin: '10px 0 0 10px' }}
          />
          <CardContent style={{ margin: '0 10px' }}>
            <h4>{intl.formatMessage({ id: 'loginFormContent' })}</h4>
            <span>
              {intl.formatMessage({ id: 'loginFormForgotPasswordDescription' })}
            </span>
            <ul>
              <li>
                {intl.formatMessage({ id: 'loginFormForgotPasswordStep1' })}
              </li>
              <li>
                {intl.formatMessage({ id: 'loginFormForgotPasswordStep2' })}
              </li>
              <li>
                {intl.formatMessage({ id: 'loginFormForgotPasswordStep3' })}
              </li>
              <li>
                {intl.formatMessage({ id: 'loginFormForgotPasswordStep4' })}
              </li>
              <li>
                {intl.formatMessage({ id: 'loginFormForgotPasswordStep5' })}
              </li>
            </ul>
          </CardContent>
          <CardContent>
            <LoginForm isLoading={isLoading} onSubmit={handleLogin}></LoginForm>
            {errorMessage && (
              <Box
                bgcolor="error.main"
                color="secondary.contrastText"
                padding={2}
                marginTop={2}
              >
                {intl.formatMessage({ id: errorMessage })}
              </Box>
            )}
            <Box p={4} textAlign="center">
              <Link href={getAgentUrl()}>
                {intl.formatMessage({ id: 'loginAgent' })}
              </Link>
            </Box>
            <Box p={4} textAlign="center">
              <Link href={getClassicUrl()}>
                {intl.formatMessage({ id: 'loginClassic' })}
              </Link>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Hero>
  )
}
type LoginSubmitArgs = {
  username: string
  password: string
}
export const LoginForm: React.FC<{
  onSubmit(args: LoginSubmitArgs): void
  isLoading?: boolean
}> = ({ onSubmit, isLoading = false }) => {
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')
  const isDisabled = isLoading || !username || !password
  const intl = useIntl()
  return (
    <form
      noValidate
      onSubmit={e => {
        e.preventDefault()

        onSubmit({
          username: username.toLowerCase(),
          password,
        })
      }}
    >
      <TextField
        variant="outlined"
        margin="normal"
        id="email"
        required
        fullWidth
        label={intl.formatMessage({ id: 'loginEmailLabel' })}
        name="email"
        onChange={e => setUsername(e.target.value)}
        value={username}
        autoComplete="email"
        autoFocus
      />
      <TextField
        variant="outlined"
        margin="normal"
        id="password"
        required
        fullWidth
        value={password}
        onChange={e => setPassword(e.target.value)}
        name="password"
        label={intl.formatMessage({ id: 'loginPasswordLabel' })}
        type="password"
        autoComplete="current-password"
      />
      <Button
        disabled={isDisabled}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
      >
        {isLoading ? (
          <CircularProgress role="alert" aria-label="Loading" />
        ) : (
          intl.formatMessage({ id: 'loginSubmit' })
        )}
      </Button>
    </form>
  )
}
