import React from 'react'

export interface AppData {
  version: string
  releaseDate: number
  lastStableVersion: string
  releaseNotes: Record<string, string>
  installPageUrl: string
}

const fetchAppData = async () => {
  const response = await fetch('app-data.json')
  return await response.json()
}

export const useAppData = () => {
  const [appData, setAppData] = React.useState<undefined | AppData>()

  React.useEffect(() => {
    fetchAppData()
      .then(data => setAppData(data))
      .catch(() => {})
  }, [])

  return appData
}
