import React from 'react'
import { FormattedMessage } from 'react-intl'

import { CtaLink } from '../../common/cta-link'
import { AppData } from '../useAppData'

export const HelpCenterButton = ({appData}:{appData?: AppData}) => {
  return (
    <>
      {
        <>
          <CtaLink href={appData?.installPageUrl || 'https://help.moia-operations.io/hc/de/articles/4915215743901'}>
            <FormattedMessage id={'heroCta'} />
          </CtaLink>
        </>
       }
    </>
  )
}
