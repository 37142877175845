import { createMuiTheme } from '@material-ui/core'
import { fade } from '@material-ui/core/styles/colorManipulator'

import globalCssOverrides from './global-css-overrides'
import {
  black,
  white,
  guidanceBlue,
  coral,
  gold,
  jade,
  moiaBlack,
} from './moia-colors'

export const DEFAULT_FONT_FAMILY = 'apercu, sans-serif'

// NOTE: [primary|secondary|error|warning|info].superlight
// is not a color used by Material UI but in the MOIA patterns
const palette = {
  common: {
    black,
    white,
  },
  // primary - used to represent primary interface elements for a user.
  primary: {
    dark: black,
    main: moiaBlack[900],
    light: moiaBlack[300],
    superlight: moiaBlack[100],
    contrastText: white,
    outlineText: moiaBlack[900],
  },
  // secondary - used to represent secondary interface elements for a user.
  secondary: {
    ...guidanceBlue,
    contrastText: white,
    outlineText: guidanceBlue.main,
  },
  // error - used to represent interface elements that the user should be made aware of.
  error: {
    ...coral,
    contrastText: white,
    outlineText: coral.main,
  },
  // warning - used to represent potentially dangerous actions or important messages.
  warning: {
    ...gold,
    contrastText: black,
    outlineText: black,
  },
  // info - used to present information to the user that is neutral and not necessarily important.
  info: {
    dark: moiaBlack[500],
    main: moiaBlack[300],
    light: moiaBlack[200],
    superlight: moiaBlack[50],
    contrastText: black,
    outlineText: black,
  },
  // success - used to indicate the successful completion of an action that user triggered.
  success: {
    ...jade,
    contrastText: white,
    outlineText: jade.main,
  },
  grey: moiaBlack,
  text: {
    primary: fade(moiaBlack['900'], 0.9),
    secondary: fade(moiaBlack['900'], 0.6),
    // NOTE: if opacity is used it adds up with the MUI disabled opacity of .5 and gets 0.5 times lighter
    disabled: fade(moiaBlack['900'], 0.7),
    hint: fade(moiaBlack['900'], 0.35),
  },
  divider: fade(moiaBlack['900'], 0.15),
  background: {
    paper: white,
    default: white,
  },
  action: {
    active: moiaBlack['500'], // NOTE: this color may not be transparent, otherwise the combinedIcons look strange
    hover: fade(moiaBlack['900'], 0.05),
    selected: fade(moiaBlack['900'], 0.08),
    disabled: fade(moiaBlack['900'], 0.27),
    disabledBackground: fade(moiaBlack['900'], 0.15),
  },

  // TODO: Milena not originally MUI Theme - refactor MoiaChip to not use it this way? See MoiaTag
  // disabled - used to indicate a disabled state of an element.
  disabled: moiaBlack[400],
}

export const themeStyles = {
  headerHeight: 80,
  inputFieldHeight: 64,
  palette,
  typography: {
    fontFamily: DEFAULT_FONT_FAMILY,
    // Not overwritten
    // fontSize: '',
    // fontWeightLight: '',
    // fontWeightRegular: '',
    // fontWeightMedium: '',
    // fontWeightBold: '',
    // h1, h2, h3, h4, h5
    h6: {
      lineHeight: '2em',
    },
    // Not overwritten
    // subtitle1, subtitle2, body1, body2
    button: {
      textTransform: 'none',
    },
    // Not overwritten
    // caption, overline
  },
  overrides: {
    MuiCssBaseline: {
      ...globalCssOverrides,
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: moiaBlack[900],
      },
    },
    MuiButton: {
      contained: {
        backgroundColor: white,
      },
    },
    MuiTableRow: {
      root: {
        height: 'auto',
        cursor: 'pointer',
      },
      head: { cursor: 'initial' },
      footer: { cursor: 'initial', height: 'auto' },
    },
    MuiTableCell: {
      head: {
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.75,
        letterSpacing: '0.00938em',
      },
      root: {
        padding: '12px 24px 12px 24px',
        '&:empty': {
          padding: 0,
        },
      },
      stickyHeader: { backgroundColor: white },
    },
    MuiInputLabel: {
      root: {
        whiteSpace: 'nowrap',
      },
    },
    MuiChip: {
      root: {
        backgroundColor: moiaBlack[200],
      },
    },
    MuiSnackbarContent: { root: { backgroundColor: white } },
    MuiTab: {
      root: {
        fontWeight: 400,
      },
      wrapper: {
        textAlign: 'center',
      },
    },
    MuiTypography: {
      caption: {
        display: 'block',
      },
    },
    MuiInput: {
      root: {
        '&$disabled': {
          opacity: '.5',
        },
      },
      underline: {
        '&:after': {
          borderBottomColor: guidanceBlue.main,
        },
      },
    },
    MuiFab: {
      root: {
        height: 50,
        width: 50,
      },
    },
    MuiSnackbar: {
      anchorOriginBottomCenter: {
        '@media (min-width: 960px)': {
          bottom: '24px',
        },
      },
    },
  },
}

// @ts-ignore
export const theme = createMuiTheme(themeStyles)
