import React from 'react'
import { Box, makeStyles } from '@material-ui/core'

import HeroImage from '../assets/hero.jpg'
import { Logo } from '../icons/logo'
import { LanguageSwitcher } from '../locales/language-switcher'

const useStyles = makeStyles(theme => ({
  hero: {
    backgroundImage: `url("${HeroImage}")`,
    minHeight: '100vh',
    color: theme.palette.common.white,
    [theme.breakpoints.up('sm')]: {
      backgroundSize: 'cover',
    },
    [theme.breakpoints.down('xs')]: {
      backgroundSize: 'cover',
      backgroundPositionX: '94%',
    },
  },
  nav: {
    paddingTop: '28px',
    position: 'relative',
    width: '100%',
  },
  logo: {
    width: '100px',
    margin: '0 auto',
  },
  switcher: {
    position: 'absolute',
    right: '0',
  },
  heroCTAContainer: {
    marginTop: '-66px',
  },
}))

export const Hero = ({ children, languageProps }: any) => {
  const styles = useStyles()

  return (
    <Box display={'flex'} flexDirection={'column'} className={styles.hero}>
      <Box className={styles.nav} display="flex" justifyContent="center">
        <Logo className={styles.logo} />
        <div className={styles.switcher}>
          <LanguageSwitcher {...languageProps} />
        </div>
      </Box>
      <Box
        flexGrow={1}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <div className={styles.heroCTAContainer}>{children}</div>
      </Box>
    </Box>
  )
}
