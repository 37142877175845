import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

import { HelpCenterButton } from './help-center-button'

import { Hero as CommonHero } from '../../common/hero'
import { AppData } from '../useAppData'

const useStyles = makeStyles(theme => ({
  heroCTAContainer: {
    width: '336px',
    whiteSpace: 'pre-wrap',
  },
  heroHeadline: {
    fontWeight: 'bold',
    fontSize: '32px',
    marginBottom: '12px',
  },
  heroDescription: {
    fontSize: '18px',
    marginBottom: '36px',
  },
  heroHelpLink: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
  }
}))

export const Hero = ({
  appData,
  languageProps,
}: {
  appData?: AppData
  languageProps: any
}) => {
  const styles = useStyles()

  return (
    <CommonHero languageProps={languageProps}>
      <div className={styles.heroCTAContainer}>
        <Typography
          className={styles.heroHeadline}
          align={'center'}
          component={'h1'}
        >
          <FormattedMessage id={'heroCTAContainer'} />
        </Typography>
        <Typography
          className={styles.heroDescription}
          align={'center'}
          component={'h2'}
        >
          <FormattedMessage
            values={{ version: appData?.version || '' }}
            id={'heroDescription'}
          />
        </Typography>
        <HelpCenterButton appData={appData} />
      </div>
    </CommonHero>
  )
}
