import React from 'react'

export const Angle: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="5"
      fill="none"
      viewBox="0 0 8 5"
    >
      <path
        fill="#fff"
        d="M0 1.414H2V6.414H0z"
        transform="rotate(-45 0 1.414)"
      />
      <path
        fill="#fff"
        d="M5.657 0H7.657V5H5.657z"
        transform="rotate(45 5.657 0)"
      />
    </svg>
  )
}
