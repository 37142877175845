import React from 'react'
import { CssBaseline, MuiThemeProvider } from '@material-ui/core'
import { IntlProvider } from 'react-intl'
import { HashRouter as Router, Switch, Route } from 'react-router-dom'

import { messages } from './locales/messages'
import { useLanguages } from './locales/language-switcher'
import { theme } from './common/theme'

import { OperationsAppPage } from './operations-app-page/operations-app-page'
import { SingleSignOnPage } from './sso/sso-page'
import { useAppData } from './operations-app-page/useAppData'

function App() {
  const languageProps = useLanguages()
  const appData = useAppData()
  return (
    <Router>
      <IntlProvider
        locale={languageProps.selectedLanguage}
        messages={messages[languageProps.selectedLanguage]}
      >
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Switch>
            <Route path="/sso">
              <SingleSignOnPage languageProps={languageProps} />
            </Route>
            <Route path="/">
              <OperationsAppPage
                appData={appData}
                languageProps={languageProps}
              />
            </Route>
          </Switch>
        </MuiThemeProvider>
      </IntlProvider>
    </Router>
  )
}

export default App
