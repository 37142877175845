import React from 'react'
import { CssBaseline } from '@material-ui/core'

import { Footer } from './sections/footer'
import { Header } from './sections/header'
import { Hero } from './sections/hero'
import { AppData } from './useAppData'

export const OperationsAppPage = ({
  languageProps,
  appData,
}: {
  languageProps: any
  appData?: AppData
}) => {
  return (
    <>
      <Header />
      <CssBaseline />
      <Hero languageProps={languageProps} appData={appData} />
      <Footer selectedLanguage={languageProps.selectedLanguage} />
    </>
  )
}
