// @ts-ignore
import ApercuBold from '../assets/font/Apercu-Bold.otf'
// @ts-ignore
import ApercuLight from '../assets/font/Apercu-Light.otf'
// @ts-ignore
import ApercuMedium from '../assets/font/Apercu-Medium.otf'
// @ts-ignore
import ApercuRegular from '../assets/font/Apercu-Regular.otf'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  '@global': {
    '@font-face': [
      {
        fontFamily: 'apercu',
        fontWeight: 500,
        src: `url("${ApercuLight}") format("truetype")`,
        textRendering: 'optimizeLegibility',
      },
      {
        fontFamily: 'apercu',
        fontWeight: 600,
        src: `url("${ApercuRegular}") format("truetype")`,
        textRendering: 'optimizeLegibility',
      },
      {
        fontFamily: 'apercu',
        fontWeight: 800,
        src: `url("${ApercuMedium}") format("truetype")`,
        textRendering: 'optimizeLegibility',
      },
      {
        fontFamily: 'apercu',
        fontWeight: 900,
        src: `url("${ApercuBold}") format("truetype")`,
        textRendering: 'optimizeLegibility',
      },
    ],
    '*': {
      boxSizing: 'border-box',
      fontFamily: 'apercu',
    },
    p: {
      margin: 0,
    },
  },
}
